import React, { useEffect, useState, useRef } from 'react';
import { A11y, Autoplay, Navigation, Scrollbar } from 'swiper/modules';
import { Base64 } from 'js-base64';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper/core';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import classNames from 'classnames';
import debounce from 'debounce';

import Image from '../Image/Image';
import styles from './UspList.module.scss';

const UspList = ({ items = [] }) => {
    const swiperRef = useRef();

    const [mounted, setMounted] = useState(false);
    const [shouldRenderSwiper, setShouldRenderSwiper] = useState(false);
    const [isActiveIndex, setIsActiveIndex] = useState(null);

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleResize = debounce(() => {
        setShouldRenderSwiper(window.innerWidth < 768);
    }, 300);

    useEffect(() => {
        setShouldRenderSwiper(window.innerWidth < 768);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const swiperItems = items.length <= 3 ? [...items, ...items] : items;

    SwiperCore.use([Autoplay]);
    const sliderSettings = {
        modules: { A11y, Autoplay, Navigation, Scrollbar },
        spaceBetween: 0,
        slidesPerView: 1.08,
        slidesPerGroup: 1,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 3000,
        },
        scrollbar: { draggable: true },
        mousewheel: {
            forceToAxis: true,
            releaseOnEdges: true,
        },
    };

    const handleClick = (index) => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideToLoop(index);
            setIsActiveIndex(index);
        }
    };

    const classes = classNames(styles['UspList'], {
        [styles['UspList--Large']]: items.length === 1,
    });

    return (
        mounted && (
            <div className={classes}>
                {items.length > 1 && shouldRenderSwiper ? (
                    <>
                        <Swiper
                            ref={swiperRef}
                            className={styles['UspList__List']}
                            onSlideChange={(swiper) => {
                                setIsActiveIndex(swiper.realIndex);
                            }}
                            {...sliderSettings}>
                            {swiperItems.map((item, index) => (
                                <SwiperSlide
                                    key={index}
                                    className={styles['UspList__Item']}>
                                    <UspCard {...item} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className={styles['UspList__Pagination']}>
                            {items.map((_, index) => (
                                <Bullet
                                    key={index}
                                    index={index}
                                    isActiveIndex={isActiveIndex}
                                    itemsLength={items.length}
                                    handleClick={handleClick}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <ul className={styles['UspList__List']}>
                        {items.map((item, index) => (
                            <li key={index} className={styles['UspList__Item']}>
                                <UspCard {...item} />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        )
    );
};

const UspCard = ({ embed = '', image = {}, link = {} }) => {
    const isEmbed = !!embed;
    const embedMarkup = isEmbed ? { __html: Base64.decode(embed) } : null;

    return (
        <>
            <a className={styles['UspList__Link']} href={link.href}>
                <span className={'sr-only'}>{link.title}</span>
            </a>
            {isEmbed ? (
                <figure className={styles['UspList__VideoContainer']}>
                    <div
                        className={styles['UspList__Video']}
                        dangerouslySetInnerHTML={embedMarkup}
                    />
                </figure>
            ) : (
                <figure className={styles['UspList__Image']}>
                    <Image image={image} loading={'eager'} sizes={'50vw'} />
                </figure>
            )}
            <h3 className={styles['UspList__Title']} aria-hidden>
                {link.title}
            </h3>
        </>
    );
};

const Bullet = ({
    isActiveIndex = null,
    index = null,
    itemsLength = 0,
    handleClick,
}) => {
    const isActive = isActiveIndex % itemsLength === index % itemsLength;

    const classes = classNames(styles['UspList__Bullet'], {
        [styles['UspList__Bullet--Active']]: isActive,
    });

    return (
        <button className={classes} onClick={() => handleClick(index)}>
            <span className={'sr-only'}>Navigate to slide {index + 1}</span>
        </button>
    );
};

UspList.propTypes = {};

export default UspList;
